import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const AboutPage = () => (
  <Layout>
   <Seo title="About" />
   <div className="w-full min-h-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto font-['Inter'] mb-20">
       <div className="grid grid-cols-12 gap-0 ">
           <div className="col-span-12 mt-20 md:mt-28 flex justify-center items-center p-4">
               <div className="w-10/12 md:w-12/12 text-4xl md:text-4xl xl:text-5xl text-center font-medium text-[#08408F] tracking-tight">
              Web's bite
               </div>
           </div>

           <div className="col-span-12 md:col-span-5 flex justify-start mt-20 p-4 md:p-8 h-auto ">
               <div className="my-10 saturate-50 backdrop-brightness-200 drop-shadow-lg">
               <StaticImage src="../images/profile.jpg" alt="Profile Ramesh" width={600} className="rounded-lg  border-4 border-blue-800" />
               </div>
           </div>
           <div className="col-span-12 md:col-span-7 mt:0 md:mt-2 flex justify-center items-center ">
               <div className="w-10/12 md:w-12/12 mx-auto mt-2">
                   <div className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal leading-8 tracking-wide">
                    <p className="my-2">Ramesh Seelamsetty has worked building digital products for over 15 years, but his last stint as a founder behind products like AdvisorsApp and YourAdvisor
                    brought him to work towards helping businesses aquire leads and prospetcs. Gaining that insight into the ever changing ecosystem of online
                    lead and prospect generation, Ramesh knew he could help online businesses achieve more by building online products the right way so as to grab a bite of the world wide web. 
                    </p>
                   <p className="my-2">Ramesh having helped others finding developer resources in India realized the challenges of find a developer resource and more so 
                    finidng one who fits right with your team with the right experience, on time. 
                    With the understanding of the cultural, timezone , language and many other differences, he went on to solve this problem by building a template which
                    priotizes communication, adaptiveness and quality solutions for our clients.
                    .   </p>
                    <p className="my-2"> Ramesh spends a significant amout to time exploring newest trends in technoloy to deliver modern solutions and reducing cost for our clients. </p>
                    <p className="my-2"> When you partner with us at Websbite, we bring all these elements in our project to deliver a cost effective quality technology solution 
                    and great client experience. </p>   
                   </div>
                </div>
           </div>
        

         
           


       </div>
   </div>
</Layout>
)


export default AboutPage
